import React from 'react';
import Layout from 'components/Layout';
import styled from 'styled-components';
import { BaseTransparentP, HeaderMedium, BaseP } from 'components/typography';
import { ImageBox, GatsbyImage } from 'components/pages/Partners/styled';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  HeaderContainer,
  RelativeWrapperWithImage,
  MainSection,
} from 'components/pages/Integrations/styled';
import { WidthWrapper } from 'components/wrappers';
import { platformCompanies, moreCompanies } from 'components/pages/Integrations/companies';
import { MobileFilter } from 'components/BackgroundFilter';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import TabNavigator from 'components/TabNavigator';
import { apiDocsLinks, apiDocsLinksChinese, apiDocsLinksKorean, apiDocsLinksJapanese } from 'components/Layout/navigation';
import Helmet from 'react-helmet';

const Wrapper = styled(WidthWrapper).attrs({ size: 980 })`
  ${HeaderMedium} {
    padding-top: 100px;
    text-align: center;
    }
  .card {
    margin-top: 10px;
  }
  p {
    margin-top: 10px;
  }
  .terminal{
    margin-left: 20px;
  }
  .top {
    margin-top: 30px;
  }
  .bottom {
    margin-bottom: 30px;
  }
  .blue {
    color: #4098f7;
  }
  .red {
    color: #e00e0e;
  }
  .header-title {
    margin-top: 40px;
    margin-bottom:10px;
  }
  h1.header-title {
    font-size:16px;
  }

   .en .zh {
    display:none;
  }

  .en .kr {
    display:none;
  }

  .en .ja {
    display:none;
  }

  .zh .en {
    display:none;
  }

  .zh .kr {
    display:none;
  }

  .zh .ja {
    display:none;
  }

  .kr .en {
    display:none;
  }

  .kr .zh {
    display:none;
  }

  .kr .ja {
    display:none;
  }

  .ja .en {
    display:none;
  }

  .ja .zh {
    display:none;
  }

  .ja .kr {
    display:none;
  }
  
`;

const TabNav = styled.div`

   .en .zh {
    display:none;
  }

  .en .kr {
    display:none;
  }

  .en .ja {
    display:none;
  }

  .zh .en {
    display:none;
  }

  .zh .kr {
    display:none;
  }

  .zh .ja {
    display:none;
  }

  .kr .en {
    display:none;
  }

  .kr .zh {
    display:none;
  }

  .kr .ja {
    display:none;
  }

  .ja .en {
    display:none;
  }

  .ja .zh {
    display:none;
  }

  .ja .kr {
    display:none;
  }

`;
const Breadcrumbs = styled.p`
    padding: 25px 0px 25px 50px;
<<<<<<< HEAD
<<<<<<< HEAD
    width:100%;
    border-top: 1px solid #e3ebe9;
    border-bottom: 1px solid #e3ebe9;
=======
    width:100%
>>>>>>> bf7b6d65e (27-09-2022)
=======
    width:100%;
    border-top: 1px solid #e3ebe9;
    border-bottom: 1px solid #e3ebe9;
>>>>>>> 7184fbe7c (28-09-22-technical-changes)
`;


const GettingStarted = () => {
const intl = useIntl()
const apiData = useStaticQuery(graphql`
    query ImgApiQuery {
      api: file(relativePath: { eq: "api-token.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  
  return (
    <Layout>
      <Helmet>
        {/* <title>Orders and Shipments API | Shipkoo</title> */}
        <title>{intl.formatMessage({ id: "ecommerce-meta-title-orders-and-shipments-api" })}</title>
        <link rel="alternate" href={intl.formatMessage({ id: "order-shipment-link" })} hreflang={intl.formatMessage({ id: "language" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "order-shipment-link-alt" })} hreflang={intl.formatMessage({ id: "language-alt" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "order-shipment-link-alt2" })} hreflang={intl.formatMessage({ id: "language-alt2" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "order-shipment-link-alt3" })} hreflang={intl.formatMessage({ id: "language-alt3" })} />
        <meta name="description" content="Access, manage and process orders using Shipkoo's orders and shipments API. Get the API token now!" />
        <meta property="og:title" content={intl.formatMessage({ id: "ecommerce-meta-title-orders-and-shipments-api" })} />
        <meta property="og:description" content="Access, manage and process orders using Shipkoo's orders and shipments API. Get the API token now!" />
        <meta name="twitter:title" content={intl.formatMessage({ id: "ecommerce-meta-title-orders-and-shipments-api" })} />
        <meta name="twitter:description" content="Access, manage and process orders using Shipkoo's orders and shipments API. Get the API token now!" />
      </Helmet>
      <TabNav>
        <div className={intl.formatMessage({ id: "language" })}>
          <div className="en">
            <TabNavigator className="en" tabs={apiDocsLinks} activeTab={apiDocsLinks[0].value} />
          </div>
          <div className="zh">
            <TabNavigator className="zh" tabs={apiDocsLinksChinese} activeTab={apiDocsLinksChinese[0].value} />
          </div>
          <div className="kr">
            <TabNavigator className="zh" tabs={apiDocsLinksKorean} activeTab={apiDocsLinksKorean[0].value} />
          </div>
          <div className="ja">
            <TabNavigator className="ja" tabs={apiDocsLinksJapanese} activeTab={apiDocsLinksJapanese[0].value} />
          </div>
        </div>
      </TabNav>
      <Breadcrumbs><Link to="/">Home</Link> / <Link to="/ecommerce-store-integration/">{intl.formatMessage({ id: "menu-platform-integrations" })}</Link> / {intl.formatMessage({ id: "menu-api-docs" })}</Breadcrumbs>
      <RelativeWrapperWithImage>
        <WidthWrapper whiteMobile>
          <Wrapper>
            <div className="card">
              <div className="card-body">
                <h1 className="header-title">
                    {intl.formatMessage({ id: "shipkoo-api-title" })}
                </h1>
                <hr />
                <p className="top">
                  {intl.formatMessage({ id: "shipkoo-api-desc" })}
                </p>
                <div className={intl.formatMessage({ id: "language" })}>
                  <p className="bottom en">
                     As a Reseller account, you are provided with your own unique <span className="blue">API Token</span> that you can use to make API calls to the SHIPKOO API 
                  </p>
                  <p className="bottom zh">
                     您成为Shipkoo酷运的会员后，我们将会提供您经销商帐户并且得到一个专属的<span className="blue">API Token</span>，可用于对SHIPKOO酷运 API进行API调适
                  </p>
                  <p className="bottom kr">
                    리셀러 계정으로 SHIPKOO API에 <span className="blue">API를</span> 호출하는 데 사용할 수 있는 고유한 API 토큰이 제공됨
                  </p>
                </div>
                <hr />
                <h4 className="header-title">
                    {intl.formatMessage({ id: "shipkoo-api-getting-your-token-title" })}
                </h4>
                <ul>
                  <li>{intl.formatMessage({ id: "shipkoo-api-getting-your-token-list1" })}</li>
                  <li>{intl.formatMessage({ id: "shipkoo-api-getting-your-token-list2" })}</li>
                </ul>
                <Img fluid={apiData.api.childImageSharp.fluid} alt="Admin Dashboard"/>
               <hr />
               <h4 className="header-title">{intl.formatMessage({ id: "shipkoo-api-making-api-call-title" })}</h4>
               <div className={intl.formatMessage({ id: "language" })}>
                <p className="top en">Use your <span className="blue">API Token</span> as a <span className="red">Bearer</span> token in the <span className="red">Authorization</span> header of the request.</p>
                <p className="top zh">在请求的<span className="red">Authorization</span>标头中将您的<span className="blue">API Token</span>用作承载Token。</p>
                <p className="top kr"><span className="blue">API</span> 토큰을 요청의 인증 헤더에서 베어러 토큰으로 사용하십시오.</p>
                <p className="top ja">リクエストの<span className="red">Authorization</span>ヘッダーでベアラートークンとして<span className="blue">API</span>トークンを使用します。</p>
              </div>
               <p>{intl.formatMessage({ id: "shipkoo-api-making-api-call-desc" })}</p>
               <p className="terminal top">$ curl https://shipkoo.com/api/product \</p>
               <p className="terminal">-H 'Authorization: Bearer {'{YOUR-API-TOKEN}'}' \</p>
               <p className="terminal bottom">-H 'Accept: application/json'</p>
               <small>{intl.formatMessage({ id: "shipkoo-api-making-api-call-note" })}</small>
              </div>
            </div>
          </Wrapper>
        </WidthWrapper>
      </RelativeWrapperWithImage>
    </Layout>
  );
};

export default GettingStarted;
